import React from "react";
import { PopupButton } from "react-calendly";

const Calendly = () => {
    if (typeof document !== `undefined`) {
        return (
            <div id="fredDiv" className="Calendly">
                <PopupButton
                    url="https://calendly.com/newpricer-gael-lebreton/let-s-talk-about-your-needs/"
                    className="talk btn btn-outline-light font-weight-lighter"

                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("___gatsby")}
                    text="Let's talk!"
                />
            </div>
        );
    } else {
        return (<span>&nbsp;</span>)
    }
};

export default Calendly;
